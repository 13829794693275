import {
  FETCH_PROJECT_LIST,
  FETCH_PROJECT_3D_LIST,
  ADD_PROJECT,
  ADD_PROJECT_FAIL,
  ADD_PROJECT_SUCCESS,
  FETCH_PROJECT_LIST_DETAILS,
  FETCH_PROJECT_LIST_DETAILS_FAIL,
  FETCH_PROJECT_LIST_DETAILS_SUCCESS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
} from "../actions";

const INIT_STATE = {
  projectList: [],
  threeDprojectList: [],
  projectDetails: [],
  add_project_success_message: "",
  project_detail_success_message: "",
  update_project_success_msg: "",
  error: "",
};

const project = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PROJECT_LIST:
      return {
        // ...state,
        projectList: action.projectList,
      };
    case FETCH_PROJECT_3D_LIST:
      return {
        // ...state,
        threeDprojectList: action.threeDprojectList,
      };
    case ADD_PROJECT:
      return {
        ...state,
        error: "",
        loading: true,
      };
    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        add_project_success_message: action.payload.message,
        error: "",
      };
    case ADD_PROJECT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        add_project_success_message: "",
      };
    case FETCH_PROJECT_LIST_DETAILS:
      return {
        ...state,
        error: "",
        loading: true,
      };
    case FETCH_PROJECT_LIST_DETAILS_SUCCESS:
      return {
        ...state,
        projectDetails: action.payload.projectDetails,
      };
    case FETCH_PROJECT_LIST_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case UPDATE_PROJECT:
      return {
        ...state,
        error: "",
        loading: true,
        update_project_success_msg: "",
      };
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        update_project_success_msg: action.payload.message,
        error: "",
      };
    case UPDATE_PROJECT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        update_project_success_msg: "",
      };
    default:
      return state;
  }
};

export default project;
