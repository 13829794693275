import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import distributorSagas from "./distributor/saga";
import retailerSagas from "./retailer/saga";
import stateSaga from "./state/saga";
import citySaga from "./city/saga";
import countrySaga from "./country/saga";
import employeeSaga from "./employee/saga";
import adminSaga from "./admin/saga";
import downloadSaga from "./download/saga";
import driverSaga from "./driver/saga";
import desktoptoolSaga from "./desktoptool/saga";
import projectSaga from "./project/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    distributorSagas(),
    retailerSagas(),
    stateSaga(),
    citySaga(),
    countrySaga(),
    employeeSaga(),
    downloadSaga(),
    driverSaga(),
    desktoptoolSaga(),
    adminSaga(),
    projectSaga(),
  ]);
}
