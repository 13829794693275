import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { ADD_CITY, FETCH_CITY, MODIFY_CITY } from "../actions";
import {
  addNewCitySuucessfull,
  fetchCitySuccessfull,
  addCityError,
  modifyCitySuccessfull,
  modifyCityFail,
  fetchCityFail,
} from "./actions";
import { API_URL } from "../../api/ApiURL";
import { AuthToken } from "../../constants/authToken";

export function* watchAddCity() {
  yield takeEvery(ADD_CITY, addNewCityFunc);
}

const newCity = async (name, state) => {
  console.log(name, state);
  try {
    const response = await axios.post(
      `${API_URL}locations/city/add/${state}`,
      // { AuthToken },
      {
        name,
        state,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

function* addNewCityFunc({ payload }) {
  const { name, state } = payload.newCity;
  try {
    const addCityResponse = yield call(newCity, name, state);

    if (addCityResponse.response) {
      if (addCityResponse.response.data.type === 2) {
        yield put(addCityError(addCityResponse.response.data.message));
      } else if (addCityResponse.response.data.type === 1) {
        yield put(addCityError(addCityResponse.response.data.message));
      } else {
        yield put(
          addCityError(addCityResponse.response.data.errors[0].message)
        );
      }
    } else {
      yield put(addNewCitySuucessfull(addCityResponse.data.data.description));
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    }
  } catch (error) {
    yield put(addCityError(error));
  }
}

/* GET CITY GENERATOR*/
export function* watchGetCity() {
  yield takeEvery(FETCH_CITY, loadCity);
}

const getCity = async (state) => {
  try {
    const response = await axios.get(`${API_URL}locations/cities/${state}`, {
      AuthToken,
    });

    return response;
  } catch (error) {
    return error;
  }
};

export function* loadCity({ payload }) {
  console.log("payload", payload);
  const { state } = payload;
  try {
    const city = yield call(getCity, state);
    if (city.response) {
      if (city.response.data.type === 1) {
        yield put(fetchCityFail(city.response.data.message));
      }
    } else {
      yield put(fetchCitySuccessfull(city.data.data.items));
    }
    // if (city) {
    // 	// yield take(FETCH_CITY_SUCCESSFULL);
    // 	yield put(fetchCitySuccessfull(city));
    // } else {
    // 	console.log("something is worng");
    // }
  } catch (error) {
    yield put(fetchCityFail(error));
    // console.log(error);
  }
}

/* MODIFY CITY GENERATOR */
export function* watchModifyCity() {
  yield takeEvery(MODIFY_CITY, modifyCityfunc);
}

const modifyCity = async (state, new_name, old_name) => {
  try {
    const response = await axios.put(
      `${API_URL}locations/city/modify/${state}`,
      { old_name, new_name, state }
    );

    return response;
  } catch (error) {
    return error;
  }
};

export function* modifyCityfunc({ payload }) {
  const { state, new_name, old_name } = payload.cityValues;
  try {
    let modifyCityVar = yield call(modifyCity, state, new_name, old_name);

    if (modifyCityVar.response) {
      if (modifyCityVar.response.data.type === 0) {
        yield put(
          modifyCityFail(modifyCityVar.response.data.errors[0].message)
        );
      } else if (modifyCityVar.response.data.type === 2) {
        yield put(modifyCityFail(modifyCityVar.response.data.message));
      } else {
        yield put(modifyCityFail(modifyCityVar.response.data.message));
      }
    } else {
      yield put(modifyCitySuccessfull(modifyCityVar.data.data.description));
      setTimeout(function () {
        window.location.reload();
      }, 2000);
    }
  } catch (error) {
    yield put(modifyCityFail(error));
  }
}
// export function* watchCity() {
// 	yield take(ADD_CITY);
// 	yield call(addNewCityFunc);
// }

export default function* rootSaga() {
  yield all([fork(watchAddCity), fork(watchGetCity), fork(watchModifyCity)]);
}
